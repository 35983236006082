import * as React from "react";
import Page from "../containers/Page";
import Hero from "../components/Hero";
import Stripes from "../images/stripes.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../style/custom-style.css";
import Anke from "../images/anke-smaele.png";

// markup
const OverFlexoffice = () => {
  return (
    <Page>
      <Hero small={true}>
        <h1>
          Meer over
          <br />
          FlexOffice
        </h1>
      </Hero>
      <div className="container-fluid">
        <div className="container">
          <section className="mt-5">
            <div className="stripes">
              <img src={Stripes} alt="" />
            </div>

            <div className="intro">
              <p>Wie is FlexOffice eigenlijk?</p>
            </div>
          </section>
        </div>
        <section className="mt-5 line-bg">
          <div className="container">
            <div className="row">
              <div className="col-sm-10">
                <h2>Anke Smaele</h2>
                <small>At your service</small>
              </div>
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-sm-10">
                  <img
                    src={Anke}
                    alt="Profielfoto van Anke Smaele"
                    width="374"
                    height="374"
                    className="ms-5 mb-5 float-lg-end rounded-3"
                  />

                  <p>
                    Naast mijn passie voor acrobatische gymnastiek, waarin ik
                    jarenlang actief was, studeerde ik Moderne Talen -
                    Wetenschappen op de middelbare school.
                  </p>
                  <p>
                    Onmiddellijk hierna ben ik aan het werk gegaan als
                    arbeidster in een weefselteeltlaboratorium. De kans deed
                    zich voor en dus nam ik deze met beide handen aan. Al snel
                    was duidelijk dat het eenzijdige werk niet voor mij was
                    weggelegd. Daarom deed ik bovenop mijn voltijdse job een
                    opleiding management assistent via avondschool. In het labo
                    kreeg ik hierdoor al snel een nieuwe, meer administratieve
                    functie, aangewezen.
                  </p>
                  <p>
                    Na meerdere jaren had ik dan toch besloten dat ik nog meer
                    uit het leven wilde halen en zocht ik een nieuwe uitdaging
                    met nieuwe doorgroeimogelijkheden.
                  </p>
                  <p>
                    Zo kwam ik in een leuke KMO terecht met een jong en heel
                    gedreven team. Mijn taak in de backoffice groeide al snel
                    uit van orderverwerking naar aankoopverantwoordelijke,
                    orderopvolging, logistiek ondersteuner, klachtenbeheerder,
                    planner en verwerker van uitgaande facturen. Na een half
                    jaar werd me dan ook nog aankoopfacturatie en
                    debiteurenbeheer toegewezen! Ja, doorgroeien ging er snel.
                  </p>
                  <p>
                    In volle Coronatijd woonde ik een webinar bij voor freelance
                    administratie en was ik onmiddellijk overtuigd. Dat wilde ik
                    gaan doen! Sterk groeiende ondernemers en groeiende KMO’s
                    bijstaan in hun administratie zodat zij meer tijd hebben
                    voor hun kernactiviteit!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="card quote">
              <div className="card-body">
                <blockquote className="blockquote">
                  <p className="text-center fst-italic">
                    Dat wilde ik gaan doen! <br />
                    Sterk groeiende ondernemers en KMO’s bijstaan in hun
                    administratie zodat zij meer tijd hebben voor hun
                    kernactiviteit!
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-sm-10">
                <h2>Ervaring</h2>
                <small>Alles, van A tot Z</small>
              </div>
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-sm-12">
                  <p>
                    In het weefselteeltlaboratorium werd ik verantwoordelijk
                    voor het verzamelen van data en deze te verwerken in
                    grafieken en verslagen. Ook aankopen van alle grondstoffen
                    met afhandeling bij levering en controle van de facturatie
                    vielen onder mijn verantwoordelijkheden.
                  </p>
                  <p>
                    Bij de KMO begon ik puur op orderinput en verwerking. Daar
                    groeide ik dan snel door tot aankoopverantwoordelijke van
                    alle kantoor – en personeelsbenodigdheden. Stelselmatig
                    kwamen daar dan ook de logistieke ondersteuning van onze
                    productie bij, behandelde ik grotendeels de binnenkomende
                    klachten. Ook werd ik planner voor transporten, waarbij soms
                    de nodige documenten voor de douane moesten aangemaakt
                    worden en deed ik het nazicht en uitzenden van de
                    verkoopfacturen. Uiteraard werd dan ook debiteurenbeheer
                    mijn verantwoordelijkheid.
                  </p>
                  <p>
                    Uiteindelijk werd ik bovenop dit alles ook assistente
                    boekhouding waarbij ik alle nodige boekhoudkundige
                    documenten moest verzamelen en controleren op juistheid om
                    deze dan in het boekhoudsysteem klaar te maken voor de
                    accountant.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container  mt-5">
            <div className="row">
              <div className="col-sm-10">
                <h2>Opleidingen</h2>
                <small>Ervaring is de beste leerschool</small>
              </div>
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-sm-12">
                  <p>
                    Als kind was ik al geïntrigeerd door vreemde talen. Dus was
                    ik blij toen ik mijn eerste franse woordjes leerde op
                    tweetalig kamp aan zee. Dit bepaalde mee mijn keuze om in
                    het middelbaar Moderne Talen - Wetenschappen te gaan doen
                    waar ik ook vlot in afstudeerde.
                  </p>
                  <p>
                    Tijdens mijn loopbaan ging ik via avondschool voor de
                    opleiding management assistent waar ik na 4 jaar en 2 babies
                    glansrijk in afstudeerde.
                  </p>
                  <p>
                    Maar het meeste heb ik uiteindelijk geleerd ‘on the job’. De
                    verschillende talen gaan gebruiken, de verschillende
                    programma’s onder de knie krijgen en dergelijke meer. Elk
                    bedrijf is anders en werkt met eigen gekozen software. Ik
                    deins er dus ook zeker niet voor terug om mijn kennis nog
                    veel meer uit te breiden.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container  mt-5 mb-5">
            <div className="row">
              <div className="col-sm-12">
                <h2>Talen</h2>
                <small>Talk the talk</small>
              </div>
            </div>
            <div className="mt-4">
              <div className="row">
                <div className="col-sm-10">
                  <p>Nederlands is mijn moedertaal.</p>
                  <p>
                    Frans en Engels ben ik heel gedreven in. Zowel mondeling als
                    schriftelijk, zakelijk of spreektaal.
                  </p>
                  <p>
                    Ook Duits behoort tot mijn kennis maar dit meer in de
                    zakelijke context.
                  </p>
                  <p>
                    Ik heb ook een notie Spaans via mijn opleiding meegekregen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default OverFlexoffice;
